import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResumeView from '../views/ResumeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/resume',
    name: 'resume',
    component: ResumeView
    // route level code-splitting
    // this generates a separate chunk (resume.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "resume" */ '../views/ResumeView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // important: maintain the order in which they are initialised.
  // to close the nav-bar menu everytime a page is loaded.
  document.body.classList.remove("nav-open");
  window.scrollTo(0, 0);
  next();
})

export default router
