<template>
  <div>
    <keep-alive>
      <pre-loader />
    </keep-alive>
    <header-ele />
    <intro-sec />
    <background-sec />
    <about-me-sec />
    <projects-sec />
    <resume-sec />
    <back-top-btn />
    <footer-ele />
  </div>
</template>

<script>
import AboutMeSec from "../components/AboutMeSec.vue";
import BackgroundSec from "../components/BackgroundSec.vue";
import BackTopBtn from "../components/BackTopBtn.vue";
import FooterEle from "../components/FooterEle.vue";
import HeaderEle from "../components/HeaderEle.vue";
import IntroSec from "../components/IntroSec.vue";
import PreLoader from "../components/PreLoader.vue";
import ProjectsSec from "../components/ProjectsSec.vue";
import ResumeSec from "../components/ResumeSec.vue";

export default {
  name: "HomeView",

  components: {
    PreLoader,
    BackgroundSec,
    IntroSec,
    AboutMeSec,
    ProjectsSec,
    ResumeSec,
    BackTopBtn,
    HeaderEle,
    FooterEle,
  },
};
</script>
