<template>
  <button
    type="button"
    @click="topFunction"
    :style="btnVisible ? 'display: block' : 'display: none'"
    id="scrollBtn"
    title="Go to top"
  >
    <font-awesome-icon icon="fa-solid fa-arrow-up" />
  </button>
</template>

<script>
export default {
  name: "BackTopBtn",
  data: function () {
    return {
      btnVisible: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  methods: {
    scrollFunction() {
      if (
        document.body.scrollTop > 250 ||
        document.documentElement.scrollTop > 250
      ) {
        this.btnVisible = true;
      } else {
        this.btnVisible = false;
      }
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>
