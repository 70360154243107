<template>
  <footer :class="this.$vuetify.theme.dark ? 'footer dark-footer' : 'footer'">
    <!-- email-address -->
    <a href="mailto:hello@ganeshrai.com" class="footer__link"
      >hello@ganeshrai.com</a
    >

    <!-- social links goes here -->
    <ul class="social-list">
      <li class="social-list__item">
        <a
          href="https://www.linkedin.com/in/ganesh-rai/"
          target="popup"
          class="social-list__link"
          aria-label="Ganesh's linkedin link"
        >
          <font-awesome-icon icon="fa-brands fa-linkedin" />
        </a>
      </li>
      <li class="social-list__item">
        <a
          href="https://twitter.com"
          target="popup"
          class="social-list__link"
          aria-label="Ganesh's twitter link"
        >
          <font-awesome-icon icon="fa-brands fa-square-twitter" />
        </a>
      </li>
      <li class="social-list__item">
        <a
          href="https://github.com/gaxrai"
          target="popup"
          class="social-list__link"
          aria-label="Ganesh's github link"
        >
          <font-awesome-icon icon="fa-brands fa-square-github" />
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "FooterEle",
};
</script>
