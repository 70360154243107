<template>
  <section class="resume" id="resume">
    <router-link to="/resume">
      <h2
        v-bind:class="
          this.$vuetify.theme.dark
            ? 'section__title section__title--resume dark-section__title--resume'
            : 'section__title section__title--resume'
        "
      >
        <i class="far fa-file"></i> My Résumé
      </h2>
      <p
        v-bind:class="
          this.$vuetify.theme.dark
            ? 'section__subtitle section__subtitle--resume dark-section__subtitle--resume'
            : 'section__subtitle section__subtitle--resume'
        "
      >
        <strong>click me</strong>
      </p>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "ResumeSec",
};
</script>
