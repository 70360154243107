<template>
  <div id="loader-wrapper">
    <div class="body">
      <span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </span>
      <div class="base">
        <span></span>
        <div class="face"></div>
      </div>
    </div>
    <div class="longfazers">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",

  mounted() {
    // remove pre-loader on page load.
    document.getElementById("loader-wrapper").classList.add("preload-finish");
  },
};
</script>
