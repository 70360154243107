<template>
  <!-- Introduction -->
  <section class="intro" id="home">
    <h1 class="section__title section__title--intro">
      Hi, I am <strong>Ganesh Rai</strong>
    </h1>
    <p class="section__subtitle section__subtitle--intro">software developer</p>
    <img
      src="../assets/ganesh-rai.webp"
      alt="a picture of Ganesh Rai"
      v-bind:class="
        this.$vuetify.theme.dark ? 'intro__img dark-intro__img' : 'intro__img'
      "
    />
  </section>
</template>

<script>
export default {
  name: "IntroSec",
};
</script>
