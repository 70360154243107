<template>
  <section
    v-bind:class="this.$vuetify.theme.dark ? 'my-work dark-my-work' : 'my-work'"
    id="work"
  >
    <h2 class="section__title section__title--work">My Projects</h2>
    <p class="section__subtitle section__subtitle--work">
      a selection of my range of work
    </p>

    <div class="projects">
      <ul class="project-items">
        <li>
          <div class="project">
            <h3>
              Implementation of Dual Three Phase Induction Motor Control Using
              Five-leg Inverter
            </h3>
            <p>
              • In order to improve industrial manufacturing processes, this
              project suggests speed control for a dual three-phase induction
              motor system that is powered by a five-leg voltage source inverter
              (FL-VSI). <br />
              • Instead of six legs, as in the traditional drive control for two
              induction motors, the inverter utilized in this method has five
              legs. <br />
              • In this project, the motors were driven using two carrier-based
              PWM schemes: Double Zero Sequence Injection and Two Arm
              Modulation. <br />
              • MATLAB Simulink software is used to examine the overall
              performance of the speed control for the five-leg voltage source
              inverter-fed dual-motor drive system.
            </p>
          </div>
        </li>
        <li>
          <div class="project">
            <h3>Load Frequency Control In A Single Area Power System</h3>
            <p>
              • This project aims at regulating the power system's fluctuating
              frequency triggered by changes in load demand on the consumer's
              end. Simulink and MATLAB were used to simulate it. <br />
              • The basic goal of load frequency management is to ensure that
              these variable steady-state errors are zero while simultaneously
              minimizing their transient changes. A reliable controller is
              implemented using a variety of contemporary control approaches.
              <br />
              • By keeping both voltage and frequency within a permitted range,
              these control approaches aim to create and supply electricity with
              reliability.
            </p>
          </div>
        </li>
        <li>
          <div class="project">
            <h3>Vertical Axis Wind Turbine (VAWT)</h3>
            <p>
              • Turbine that are characterized by an axis of rotation that is
              perpendicular to the ground.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProjectsSec",
};
</script>
