<template>
  <div>
    <resume-header-ele />
    <resume-body />
    <back-top-btn />
    <footer-ele />
  </div>
</template>

<script>
import ResumeBody from "@/components/ResumeBody.vue";
import BackTopBtn from "@/components/BackTopBtn.vue";
import FooterEle from "@/components/FooterEle.vue";
import ResumeHeaderEle from "@/components/ResumeHeaderEle.vue";

export default {
  name: "ResumeView",

  components: {
    ResumeBody,
    BackTopBtn,
    FooterEle,
    ResumeHeaderEle,
  },
};
</script>
