<template>
  <section
    v-bind:class="
      this.$vuetify.theme.dark ? 'about-me dark-about-me' : 'about-me'
    "
    id="about"
  >
    <h2 class="section__title section__title--about">Who am I?</h2>
    <p class="section__subtitle section__subtitle--about"></p>

    <div class="about-me__body">
      <p>
        Ganesh Rai here, as you are fully aware. <br />
        I'm a native of Karnataka's Mangalore. Before relocating to Mumbai,
        where I completed my education through the 12th grade, I attended school
        in Gujarat through the sixth grade. I'm an electrical and electronics
        engineer from Ramaiah Institute of Technology, Bangalore. Where I also
        got to learn alot about entrepreneurship.
      </p>
      <p>
        Being actively involved in practical projects at the university had been
        really enriching, and we've also had the chance to participate in a
        variety of sports and extracurricular activities. I was a member of
        university's 5-man table tennis team, where together we have won
        numerous awards at various competitions. Because of how tense these
        competitions can be, they have also taught me how to remain composed
        under pressure.
      </p>
      <p>
        I consider myself to be a very diligent person who is also a team player
        and easily flexible to any situation. My immediate objective is to
        increase my performance metrics and maintain focus. Being a trustworthy,
        knowledgeable individual with a respected position in my firm is my
        long-term objective.
      </p>
    </div>

    <img
      src="../assets/ganesh-rai-standing.webp"
      alt="Ganesh Rai holding a mic on stage"
      v-bind:class="
        this.$vuetify.theme.dark
          ? 'about-me__img dark-about-me__img'
          : 'about-me__img'
      "
    />
  </section>
</template>

<script>
export default {
  name: "AboutMeSec",
};
</script>
