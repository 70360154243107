<template>
  <div>
    <section class="intro" id="home">
      <h1 class="section__title section__title--intro">
        Innovator. Flexible.<strong>Firefighter.</strong>
      </h1>
      <p class="section__subtitle section__subtitle--intro">My Résumé</p>
      <img
        width="250"
        height="105.33"
        src="@/assets/pen.png"
        alt="a pen scribbling"
        class="intro__img portfolio-img"
        v-bind:style="
          this.$vuetify.theme.dark
            ? '-webkit-filter: invert(1); filter: invert(1)'
            : '-webkit-filter: invert(0); filter: invert(0)'
        "
      />
    </section>

    <div class="portfolio-item-individual">
      <img
        src="../assets/Ganesh-Resume.jpg"
        alt="picture of ganesh rai's resume"
        class="resume resume-img1"
      />
    </div>

    <div class="contact-me">
      <p>Want to Contact Me? Use the link to my email-address.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumeBody",
};
</script>
