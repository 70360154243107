import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    default: 'dark',
    disable: false,
    themes: {
      dark: {
        anchor: "#16e0bd",
      },
      light: {
        anchor: "#16e0bd",
      },
    },
  },
});
