<template>
  <header>
    <div class="logo">
      <router-link to="/">
        <img
          width="140"
          height="25.59"
          src="../assets/logo.svg"
          alt="logo for ganesh rai"
          v-bind:style="
            this.$vuetify.theme.dark
              ? '-webkit-filter: invert(1); filter: invert(1)'
              : '-webkit-filter: invert(0); filter: invert(0)'
          "
        />
      </router-link>
    </div>

    <!-- Hamburger Menu -->
    <button
      @click="toggleNav"
      class="nav-toggle"
      aria-label="toggle navigation"
    >
      <span class="hamburger"></span>
    </button>

    <!-- Navigation Links -->
    <nav class="nav">
      <ul class="nav__list resume-nav-item">
        <li class="nav__item">
          <router-link to="/" @click.native="removeNav" class="nav__link">
            <p>
              GO BACK TO<br />
              HOMEPAGE
            </p>
          </router-link>
        </li>
        <li class="nav__item">
          <v-btn
            @click="
              toggle_dark_mode();
              removeNav();
            "
            class="nav__link"
          >
            Toggle Dark Mode &nbsp;
            <font-awesome-icon icon="fa-solid fa-circle-half-stroke" />
          </v-btn>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderEle",

  methods: {
    toggleNav() {
      document.body.classList.toggle("nav-open");
    },
    removeNav() {
      document.body.classList.remove("nav-open");
    },
    toggle_dark_mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },

  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
};
</script>
