<template>
  <!-- Brief About-Me -->
  <section
    v-bind:class="
      this.$vuetify.theme.dark ? 'my-services dark-my-services' : 'my-services'
    "
    id="services"
  >
    <h2 class="section__title section__title--services">What I do</h2>
    <div class="services">
      <div class="service">
        <h3>Engineer</h3>
        <p>
          I graduated from Ramaiah Institute of Technology in Bangalore as an
          engineer with a focus in electrical and electronics engineering. As I
          neared graduation, my ideas began to take flight as I discovered a
          variety of things I could accomplish with the items in my immediate
          environment.
        </p>
      </div>
      <!-- / service -->

      <div class="service">
        <h3>Web Development</h3>
        <p>
          I enjoy working on websites and dabbling in the front end web
          occasionally for fun. The sheer number of possibilities that web
          development offers, as well as the numerous adjustments and
          alterations you can make to a website before presenting it to the
          public, fascinates me.
        </p>
      </div>
      <!-- / service -->

      <div class="service">
        <h3>Hobbies</h3>
        <p>
          Along with various sports like football, table tennis, basketball, and
          chess, I do enjoy playing video games as well. Watching YouTube is
          also one of them but people don't typically classify watching YouTube
          as a hobby, perhaps because we do it so frequently that we lose sight
          of its status as such.
        </p>
      </div>
      <!-- / service -->
    </div>
    <!-- / service -->

    <a href="#work" class="btn" style="color: #303030">My Projects</a>
  </section>
</template>

<script>
export default {
  name: "BackgroundSec",
};
</script>
